// GRID
$size-grid-padding: 1.3rem;

// CONTENT
$size-content-width-max: 50rem;
$size-content-width-min: 25rem;

// INPUTS
$size-input-padding-vertical: 0.75em;
$size-input-padding-horizontal: 1em;
$size-input-padding: $size-input-padding-vertical $size-input-padding-horizontal;
$size-input-border: 1px;
$size-input-border-radius: (1em + $size-input-padding-vertical * 2) / 10;

// BUTTONS
$size-button-padding-vertical: $size-grid-padding / 2;
$size-button-padding-horizontal: $size-grid-padding / 1.5;
$size-button-padding: $size-button-padding-vertical
  $size-button-padding-horizontal;

// default font size is 30 px (my guess)
$size-font-default: 30px;
$size-font-larger: 2.67rem; // 80px
$size-font-large: 2.27rem; // 68px
$size-font-bigger: 1.34rem; // 34px
$size-font-big: 1.14rem; // 30px
$size-font-medium: 1rem;
$size-font-small: 0.8rem; // 24px
$size-font-smaller: 0.6rem; // 18px
$size-font-thin: 0.47rem; // 14px
$size-font-thinner: 0.34rem; // 10px
